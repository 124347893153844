import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn: boolean;

  constructor(private afAuth: AngularFireAuth, private localStorageService: LocalStorageService, private router: Router) {
    this._isLoggedIn = !!this.localStorageService.getActiveUID();
  }

  get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  login(email: string, password: string) {
    this.afAuth.auth.signInWithEmailAndPassword(email, password).then((data) => {
      this._isLoggedIn = true;
      this.localStorageService.setActiveUID(data.user.uid);
      this.router.navigate(['achievements']);
    }).catch(error => {
      console.error(error);
      this._isLoggedIn = false;
    });
  }

  logout(): void {
    this.afAuth.auth.signOut().then((res) => {
      this.localStorageService.clearActiveUID();
      this.router.navigate(['login']);
    }).catch(error => {
      console.error(error);
    });
  }

  register(email: string, password: string): void {
    this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(data => {
      this._isLoggedIn = true;
      this.localStorageService.setActiveUID(data.user.uid);
      this.router.navigate(['achievements']);
    }).catch(error => console.error(error));
  }
}
