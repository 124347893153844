import { NgModule } from '@angular/core';
import { MatButtonModule, MatCardModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';



@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [
    MatFormFieldModule, MatInputModule, MatButtonModule, MatCardModule, MatDialogModule, MatSelectModule
  ],
  exports: [
    FormsModule,
    MatFormFieldModule, MatInputModule, MatButtonModule, MatCardModule, MatDialogModule, MatSelectModule
  ],
  entryComponents: [
    ConfirmDialogComponent
  ]
})
export class AkCommonModule { }
