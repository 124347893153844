import { Injectable } from '@angular/core';

enum STORAGE_KEYS {
  ACTIVE_UID = 'ACTIVE_UID'
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() {
  }

  getActiveUID(): string {
    return localStorage.getItem(STORAGE_KEYS.ACTIVE_UID);
  }

  setActiveUID(email: string): void {
    localStorage.setItem(STORAGE_KEYS.ACTIVE_UID, email);
  }

  clearActiveUID(): void {
    localStorage.removeItem(STORAGE_KEYS.ACTIVE_UID);
  }
}
